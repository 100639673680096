@import '../../../global';

.DataProcessingSection {
  position: relative;
  padding-bottom: 50px;
  color: white;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  user-select: none;

  .videoIntro {
    display: flex;
    flex-direction: row;
    margin: 40px 0;
    .text {
      width: 35%;
      padding: 10px 20px;
      
    }
    .video {
      width: 65%;
      height: 350px;
      padding: 20px 30px;
      box-sizing: border-box;
    }

    @include media("<=phone") {
      display: block;
      margin: 10px 0;
      .text, .video {
        height: auto;
        width: 100%;
        padding: 10px 0;
      }
      .video {
        height: 60vw;
      }
    }

  }

  p.hideMobile {
    @include media("<=phone") {
      display: none;
    }
  }

  p.mobileOnly {
    @include media(">phone") {
      display: none;
    }
  }

  .text {
    padding: 30px 20px;
    p {
      font-size: 16px;
    }
  }
  
  .evaGallery {
    display: flex;
    flex-direction: row;
    .column {
      width: 33%;
    }
    @include media("<=phone") {
      display: block;
      margin-left: -20px;
      :global(.swiper-container) {
        --swiper-navigation-color: white;
        width: 100vw;
        margin: 25px 0;

        :global(.swiper-button-prev) {
          left: 5px;
        }

        :global(.swiper-button-next) {
          right: 5px;
        }
        :global(.swiper-wrapper) {
          :global(.swiper-slide) {
            >div {
              margin: 0 auto;
              width: 80vw;
              height: 80vw;
              max-height: 100vh;
              >img {
                margin: 0 auto;
                display: block;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
  .moreInfoButton {
    margin: 40px 0 20px;
    text-align: center;
  }
  
  &:after {
    content: "";
    background-color: $colorMainDarkBlue;
    background-image: url(../../../assets/backgrounds/orange_grid.png);
    opacity: 0.9;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;

    @include media("<=phone") {
      opacity: 0.9;
    }
  }
}
