@import 'src/global';

.CookieConsentNotification {
  position: fixed;
  bottom: 0;
  background: #333;
  color: white;
  border-top: 5px solid white;
  z-index: 999;

  .consentText {
    overflow: scroll;
    
    a {
      color: white;

      &:hover {
        color: $colorMainRed;
      }
    }

    > h2 {
      padding: 0 10px;
    }

    > p {
      padding: 0 10px;
      font-size: 12px;
    }
  }

  .buttons {
    text-align: center;
    margin: 0 20% 10px;
    .button {
      box-sizing: border-box;
      display: inline-block;
      padding: 7px 30px;
      margin: 0 15px;
      cursor: pointer;
      border: 4px solid black;
      text-decoration: none;
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      background: $colorMainGreen;
      color: white;
      
      &.dark {
        background: black;
        color: white
      }

      &:hover {
        transition-duration: 500ms;
        border: 4px solid black;
        background: white;
        color: black;
      }
    }
  }

  @include media("<=phone") {
    .consentText {
      max-height: 30vh;
      > p:last-child {
        margin-bottom: 36px;
      }
    }
    
    .buttons {
      position: relative;
      margin: 0 0 5px;
      .button {
        padding: 5px 20px;
        font-size: 14px;
      }
      
      &:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: -36px;
        height: 36px;
        background: linear-gradient(180deg,rgba(51,51,51,0) 0,rgba(51,51,51,.5) 30%,#333 100%);
        pointer-events: none;
      }
    }
  }
}