@import '../global';

.headline {
  text-align: center;
  width: 50%;
  margin: 0 auto 50px;
  border-bottom: 6px solid black;
  font-size: 35px;
  padding-bottom: 10px;
  font-weight: bold;

  @include media("<=phone") {
    width: 100%;
    font-size: 32px;
  }
}

.content {
  margin: 0 20px;
  p {
    font-size: 15px;  
  }
  @include media("<=phone") {
    margin: 0 10px;
  }
}

.bigP {
  font-size: 17px;
  margin: 30px 0;
}