.Main {
  background: url('../../assets/backgrounds/vector-network-background-abstract-polygon-triangle/76.jpg');
  background-attachment: fixed;
  background-size: 150vw;
  background-position-y: calc(-200px + var(--scroll-background)*200px);
  z-index: 0;
  position: relative;
  
  p {
    font-size: 16px;
  }
}