@import '../../../global';

.ExamplesSection {
  z-index: 0;
  position: relative;
  padding-bottom: 50px;

  .blendingBackground {
    
    .mobileOnlyLine {
      background: url('../../../assets/backgrounds/header-line.png');
      background-size: cover;
      background-repeat: no-repeat;
      height: 6px;
      width: 100%;
      margin: 25px 0;
      transform: rotate(-1.2deg);
      @include media(">phone") {
        display: none;
      }
    }
  }

  .blendingBackground::after {
    content: "";
    background-color: white;
    background-image: url(../../../assets/backgrounds/squared_metal/squared_metal_@2X.png);
    background-size: 134px;
    opacity: calc(var(--scroll-enter) * 0.9);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;

    @include media("<=phone") {
      opacity: 0.9;
    }
  }
}
