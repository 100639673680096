@import '../../../global';

.ToShopSection {
  position: relative;
  padding-top: 30px;
  padding-bottom: 80px;
  
  .sectionContainer {
    width: $pageWidthFull*0.9;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    position: relative;
    
    .bigIcon {
      width: 50%;
      > a {
        display: block;
        position: relative;
        width: 300px;
        height: 300px;
        >img {
          position: absolute;
        }
        >img.logo {
          top: 40px;
          left: 150px;
          width: 140px;
          transform: rotate(35deg);
        }
        >img.basket {
          top: 120px;
          left: 100px;
        }
      }
    }
    .toShopButton {
      position: absolute;
      top: 70%;
      left: 60%;
      transform: translateY(-50%);
    }

    @include media("<=phone") {
      width: 100%;
      display: block;
      .bigIcon {
        width: 100%;
        height: 450px;
        >a {
          >img.logo {
            top: 70px;
            left: 50%;
            transform: rotate(35deg) translateX(-40%);
          }
          >img.basket {
            top: 150px;
            left: 50%;
            transform: translateX(-40%);
          }
        }
      }
      .toShopButton {
        position: relative;
        top: auto;
        left: auto;
        width: fit-content;
        margin: 20px auto 0;
      }
    }
  }

  &:after {
    content: "";
    background-color: $colorMainGreyBlue;
    background-image: url(../../../assets/backgrounds/yellow_grid.png);
    opacity: 0.9;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }
}
