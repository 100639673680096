@import '../../global';

.ProductPageDescription {
  p {
    font-size: 15px;
    line-height: 30px;
  }

  ul {
    margin: 5px 0;

    > li {
      margin-bottom: 8px;
    }
  }

  .columns-left-right, .columns-right-left {
    display: flex;
    flex-direction: row;

    > div {
      padding: 25px;
    }

    div.product-image > img {
      width: 400px;
    }

    @include media("<=phone") {
      display: block;
      > div {
        padding: 10px;
      }
      div.product-image > img {
        width: 100%;
        box-sizing: border-box;
      }
    }
  }

  .columns-right-left {
    flex-direction: row-reverse;
  }

  .animated-logo {
    display: inline-block;
    top: 39px;
    position: relative;
    margin-left: 30px;

    > img {
      height: 100px;
    }
  }
}