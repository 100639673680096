@import '../variables';

.BigActionButton {
  box-sizing: border-box;
  display: inline-block;
  min-width: 200px;
  padding: 10px 75px;
  cursor: pointer;
  border: 4px solid black;
  color: black;
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  background: white;
  
  &:hover {
      transition-duration: 500ms;
      color: white;
      border: 4px solid black;
      background: $colorMainGreen;
  }
}

.darkBackground {
  border: 4px solid white;
  color: white;
  background: none;
}