@import "../global";

.DefaultContentWrapper {
  .page-container {
    margin: 0 auto;
    width: $pageWidthFull;
    @include media("<=phone") {
      width: 100%;
      padding: 0 20px;
      box-sizing: border-box;
    }
  }
}