@import '../../global';

.ProductConfigurationMenu {
  background: rgba(27, 153, 234, 0.15);
  padding: 15px;
  position: relative;
  margin-left: 10px;
  @include media("<=phone") {
    margin-left: 0;
  }

  > h1 {
    font-family: $font-stack-marker;
    font-size: 20px;
    font-weight: normal;
    text-align: center;
    transform: rotate(-2deg);
    margin: 0;
  }
  
  .productOption {
    margin-top: 10px;
    height: 60px;
    
    > h3 {
      font-size: 14px;
      font-weight: bold;
      margin: 0;
      >span {
        font-weight: normal;
      }
    }
    
    .selectBox {
      width: 100%;
      height: 30px;
      border: 1px solid black;
      font-family: $font-stack;
      font-size: 15px;
      padding: 2px 15px;
      appearance:none;
      cursor: pointer;
      box-sizing: border-box;
      background: white;
      opacity: 0.5;

      &:focus {
        border-width: 2px;
        outline: 0;
      }
      &:hover {
        background: #eff8ef;
      }
      
    }
    .arrow {
      position: relative;
      display: inline;
      left: 90%;
      top: -27px;
      pointer-events: none;
      font-size:4px;
    }

    &:before {
      content: "";
      display: table;
      clear: both;
    }

    &.disabled{
      > select {
        opacity: 0.4;
      }
    }
  }
  .leftArrow {
    position: absolute;
    left: -10px;
    top: 50%;
    margin-top: 8px;
    border: solid transparent;
    border-width: 10px 10px 10px 0;
    border-right-color: rgba(27, 153, 234, 0.15);

    @include media("<=phone") {
      display: none;
    }
  }

  .currentAtom {
    border: 1px solid grey;
    background: rgba(150, 150, 150, 0.1);
    border-radius: 4px;
    padding: 10px;
    height: 145px;
    margin-top: 10px;
    >h1 {
      font-size: 14px;
      font-weight: bold;
      margin: 0;
      text-align: center;
      border-bottom: 1px solid grey;
    }
    .productOption {
      .colors {
        .colorTile {
          float: left;
          background-size: cover;
          width: 40px;
          height: 40px;
          box-sizing: border-box;
          margin: 3px;
          border: 1px solid grey;
          cursor: pointer;
          &:hover {
            border: 1px solid black;
          }
          &.activeColor {
            border: 2px solid #333;
          }
        }
      }
      &.disabled{
        .colorTile {
          opacity: 0.4;
          &.activeColor {
            opacity: 0.9;
          }
        }
      }
    }
  }
  
  .notAvailable {
    opacity: 0;
    position: absolute;
    top: 50%;
    width: 80%;
    margin: 0 auto;
    transform: translateX(20px) translateY(-50%);
    background: rgba(255, 255, 255, 0.9);
    padding: 20px;
    border-radius: 10px;
    box-sizing: border-box;
    box-shadow: 1px 1px 5px 2px #333;
    transition: opacity 0.5s;
    pointer-events: none;
    
    &.showMessage {
      opacity: 1;
    }
  }
}