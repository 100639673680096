@import '../../global'; 

.ProductPageTop {
  display: flex;
  .top-carousel {
    flex: 1;
    padding: 10px;
  }
  .top-right {
    margin-left: 30px;
    width: 400px;

    .top-right-logo > img {
      height: 128px;
    }
    
    > h1 {
      margin: 0;
      font-size: 24px;
      
    }
    
    >p {
      font-size: 15px;
      margin: 6px 0 12px;
      
      a {
        text-decoration: none;
        &:hover {
          background: $colorMainDarkBlue;
          color: white;
        }
      }
    }

    @include media("<=phone") {
      .top-right-logo, > h1, > p {
        text-align: center;
      }
    }
  }

  @include media("<=phone") {
    display: block;
    .top-carousel, .top-right {
      width: 100%;
      margin: 0;
      padding: 0;
    }
  }
}