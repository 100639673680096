@import "variables.scss";

@font-face {
  font-family: "SpaceMono";
  font-weight: normal;
  src: url(assets/fonts/SpaceMono-Regular.ttf);
}

@font-face {
  font-family: "SpaceMono";
  font-weight: bold;
  src: url(assets/fonts/SpaceMono-Bold.ttf);
}

@font-face {
  font-family: "SpaceMono";
  font-style: italic;
  src: url(assets/fonts/SpaceMono-Italic.ttf);
}

@font-face {
  font-family: "SpaceMono";
  font-weight: bold;
  font-style: italic;
  src: url(assets/fonts/SpaceMono-BoldItalic.ttf);
}

@font-face {
  font-family: "SpaceMono";
  font-weight: bold;
  src: url(assets/fonts/SpaceMono-Bold.ttf);
}

@font-face {
  font-family: "IndieFlower";
  font-weight: normal;
  src: url(assets/fonts/IndieFlower-Regular.ttf);
}

@font-face {
  font-family: "PermanentMarker";
  font-weight: normal;
  src: url(assets/fonts/PermanentMarker-Regular.ttf);
}

body {
  font: 100% $font-stack;
  font-size: 14px;
}

p {
  font-size: 13px;
}

a {
  text-decoration: underline;
  color: $colorText;
}

h1 {
  font-size: 42px;
  font-weight: normal;
}

h2 {
  font-size: 16px;
  font-weight: bold;
}

button {
  font: 100% $font-stack;
}

div, p, h1, h2, h3, h4 {
}
