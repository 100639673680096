.LedRingAtom {
  --atom-image-width: 937px;
  --atom-image-height: 910px;
  --atom-image-zero-x: 678px;
  --atom-image-zero-y: 637px;
  background-image: url("./images/led-ring_plain.png");
  position: relative;
  
  
  .lighton {
    --light-red: 255;
    --light-green: 255;
    --light-blue: 255;
    --light-brightness: calc(max(var(--light-red), var(--light-green), var(--light-blue))/255);
    position: absolute;
    top: 513px;
    left: 166px;
    .sourceSpread {
      position: absolute;
      background: radial-gradient(rgba(255, 255, 255,.05) 10%, rgba(255,225,255,.05) 30%, rgba(0,0,0,0) 60%);
      border-radius: 50%;
      width: 100px;
      height: 100px;
      margin-top: -50px;
      margin-left: -50px;
      top: 14%;
      left: 14%;
      z-index: 0;
    }

    .source {
      position: absolute;
      background: radial-gradient(
                      rgba(255, 255, 255,var(--light-brightness)) 0%,
                      rgba(var(--light-red),var(--light-green),var(--light-blue),calc(var(--light-brightness)*0.4)) 55%,
                      rgba(var(--light-red),var(--light-green),var(--light-blue),.0) 70%);
      border-radius: 50%;
      width: 70px;
      height: 70px;
      margin-top: -35px;
      margin-left: -35px;
      top: 14%;
      left: 14%;
      z-index: 2;
    }

    .sourceBeam {
      position: absolute;
      background: radial-gradient(
                      rgba(255, 255, 255,.5) 0%,
                      rgba(var(--light-red),var(--light-green),var(--light-blue),.5) 20%,
                      rgba(0,0,0,0) 70%);
      border-radius: 50%;
      width: calc(300px*var(--light-brightness));
      height: calc( 40px*var(--light-brightness));
      margin-top:  calc(-20px*var(--light-brightness));
      margin-left:  calc(-150px*var(--light-brightness));
      top: 14%;
      left: 14%;
      z-index: 1;
      transform: rotate(-60deg);
    }
  }
}