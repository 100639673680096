@import "../../../../global";

.SingleNumber {
  display: inline-block;
  height: 28px;
  margin: 3px;
  padding: 0 15px;
  border: 3px solid #333;
  background: white;
  border-radius: 10px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}