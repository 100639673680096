$colorText: black;
$colorTextDarkBg: white;
$colorMainDarkBlue: #012e4e;
$colorMainRed: #bb102a;
$colorMainGreen: #45d178;
$colorMainOrange: #fc6b04;
$colorMainGreyBlue: #4c586a;

$pageWidthFull: 920px;

$font-stack: "SpaceMono", Monospace;
$font-stack-handwriting: "IndieFlower", SansSerif;
$font-stack-marker: "PermanentMarker", SansSerif;
