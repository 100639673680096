@import '../../../global';

.ChatIntroSection {
  position: relative;
  background: white;
  width: 100%;
  height: 500vh;


  .fixableWrapper {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }

  .content {
    width: $pageWidthFull;
    margin: 0 auto;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    @include media("<=phone") {
      width: 100%;
    }
  }

  .SectionBackground {
    content: "";
    background: url('../../../assets/images/logo_bg_animated.gif');
    background-size: cover;
    background-attachment: fixed;
    opacity: 0.5;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: 0;
    @include media("<=phone") {
      background: url('../../../assets/backgrounds/vector-network-background-abstract-polygon-triangle/76.jpg');
      background-size: 400vh;
      background-position-y: -200px;
      opacity: 1;
    }
  }

  .ctaResponse {
    color: white;
    font-size: 20px;
    font-weight: bold;

    > a {
      color: white;
    }
  }
  
  .scrollHint {
    color: #333;
    margin: 10px auto;
    width: 150px;
    position: relative;
    transform: translateX(30px);
    font-size: 20px;
    >img {
      position: absolute;
      height: 80px;
      left: -60px;
      top: -5px;
    }
    @include media("<=phone") {
      position: absolute;
      bottom: 0;
      font-size: 14px;
      background: rgba(255, 255, 255, 0.4);
      padding: 10px 10px 10px 60px;
      border-radius: 10px;
      left: 50%;
      transform: translateX(-50%);
      >img {
        position: absolute;
        height: 50px;
        left: 17px;
        top: 7px;
      }
    }
    opacity: 1;
    transition: opacity 0.5s;
    &.hide {
      opacity: 0;
    }
  }
}