@import 'src/global';

.background {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0;
  background: rgba(51, 51, 51, 0.4);
  z-index: 1999;
}

.DataPrivacySettings {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 20px;
  background: #333;
  color: white;
  border: 3px solid white;

  overflow: scroll;

  > h2, >p {
    padding: 0 10px;
  }
  
  .settings {
    .setting {
      display: flex;
      flex-direction: row;
      margin-bottom: 20px;
      /* The switch - the box around the slider */
      .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;
        margin: 25px;
      }
  
      /* Hide default HTML checkbox */
      .switch input {
        opacity: 0;
        width: 0;
        height: 0;
      }
  
      /* The slider */
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 34px;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
      }
  
      .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        border-radius: 50%;
        -webkit-transition: .4s;
        transition: .4s;
      }
  
      input:checked + .slider {
        background-color: $colorMainGreen;
      }
  
      input:focus + .slider {
        box-shadow: 0 0 1px $colorMainGreen;
      }
  
      input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
      }
      
      .description {
        flex: 1;
        >h1 {
          font-size: 16px;
          font-weight: bold;
          margin: 0;
        }
        >p {
          margin: 0;
        }
      }
    }
  }

  .buttons {
    text-align: center;

    .button {
      box-sizing: border-box;
      display: inline-block;
      padding: 7px 30px;
      margin: 0 15px;
      cursor: pointer;
      border: 4px solid black;
      text-decoration: none;
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      background: $colorMainGreen;
      color: white;

      &.dark {
        background: black;
        color: white
      }

      &:hover {
        transition-duration: 500ms;
        border: 4px solid black;
        background: white;
        color: black;
      }
    }
  }

  @include media("<=phone") {
    .consentText {
      max-height: 30vh;

      > p:last-child {
        margin-bottom: 36px;
      }
    }

    .buttons {
      position: relative;
      margin: 0 0 5px;

      .button {
        display: block;
        box-sizing: border-box;
        width: calc(100% - 20px);
        padding: 5px 20px;
        font-size: 14px;
        margin: 10px;
      }
    }
  }
}