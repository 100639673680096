@import '../../../global';

.ChatCarouselMessage {
  position: relative;
  background: $colorMainOrange;
  border-radius: 30px;
  margin: 50px 30px;
  padding: 30px;
  color: white;
}

.ChatCarouselMessage:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 75%;
  width: 0;
  height: 0;
  border: 45px solid transparent;
  border-top-color: $colorMainOrange;
  border-bottom: 0;
  border-right: 0;
  margin-left: -15px;
  margin-bottom: -30px;
}

.response {
  background: $colorMainDarkBlue;
}

.response:after {
  left: 25%;
  border: 45px solid transparent;
  border-top-color: $colorMainDarkBlue;
  border-bottom: 0;
  border-left: 0;
}
