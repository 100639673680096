.ShopBackground {
  position: relative;
  height: 0;
  
  .scrolling-background {
        position: fixed;
        top: 0;
        left: 0;
        height: 200vh;
        width: 100vw;
         z-index: -100;
        opacity: 0.25;
        background-size: auto;
        background-position: left top;
        background-repeat: repeat;
        background-image: url(../../assets/backgrounds/green_cross.png);
  }
}
