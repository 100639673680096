@import '../global.scss';

footer.PageFooter {
  background: $colorMainGreen;
  .footer-container {
    margin: 0 auto;
    padding: 50px 0 70px;
    display: flex;
    width: $pageWidthFull;
    flex-direction: row;
    @include media("<=phone") {
      width: 100%;
    }

    .left-logo{
      img {
        height: 220px;
      }
      @include media("<=phone") {
        display: none;
      }
    }
    
    .column {
      color: $colorTextDarkBg;
      margin: 0 0 0 40px;
      width: 200px;
      font-size: 14px;
      text-align: left;
      @include media("<=phone") {
        margin: 0 20px;
      }

      h3 {
        font-size: 14px;
        font-weight: bold;
        margin: 0;
        padding: 10px 0;
        margin-bottom: 10px;
        border-bottom: 3px solid $colorTextDarkBg;
      }
      
      p {
        font-size: 12px;
        font-weight: bold;
        a {
          text-decoration: underline;
          color: $colorTextDarkBg;
        }
      }
      
      ul {
        margin: 0;
        padding: 0;
        li {
          display: block;
          margin: 0 0 14px;
          text-align: left;
          a {
            text-decoration: underline;
            color: $colorTextDarkBg;
            text-transform: uppercase;
            cursor: pointer;
          }
        }
      }
    }

    @include media("<=phone") {
      .column:last-child {
        display: none;
      }
    }

  }
  .mobile-logo {
    display: none;
  }
  @include media("<=phone") {
    .mobile-logo {
      display: block;
      text-align: center;
      margin-top: -30px;
      padding-bottom: 50px;
      img {
        width: 180px;
      }
    }
  }
}