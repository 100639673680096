.KeyboardAtom {
  --atom-image-width: 1772px;
  --atom-image-height: 894px;
  --atom-image-zero-x: 1530px;
  --atom-image-zero-y: 637px;
  background-image: url('./images/keyboard_plain.png');

  .key {
    position: absolute;
    top: 563px;
    width: 163px;
    height: 163px;

    &.key1 {
      left: 119px;
    }

    &.key2 {
      left: 321px;
    }

    &.key3 {
      left: 512px;
    }

    &.key4 {
      left: 701px;
    }

    &.key5 {
      left: 892px;
    }

    &.key6 {
      left: 1082px;
    }

    &.key7 {
      left: 1274px;
    }

    &.key8 {
      left: 1464px;
    }
  }

  .keyon {
    background-image: url('./images/keyboard_keypressed.png');
    background-repeat: no-repeat;
    background-position: top 400px left;
    background-size: auto;
  }

  .key1on {
    background-position-x: 0;
  }

  .key2on {
    background-position-x: calc(191px * 1);
  }

  .key3on {
    background-position-x: calc(191px * 2);
  }

  .key4on {
    background-position-x: calc(191px * 3);
  }

  .key5on {
    background-position-x: calc(191px * 4);
  }

  .key6on {
    background-position-x: calc(191px * 5);
  }

  .key7on {
    background-position-x: calc(191px * 6);
  }

  .key8on {
    background-position-x: calc(191px * 7);
  }
}