@import '../../../global';

.IntroSection {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  @include media("<=phone") {
    height: 90vh;
  }


  .pageContent {
    width: $pageWidthFull;
    margin: 0 auto;
    position: relative;
    height: 100%;

    @include media("<=phone") {
      width: 100%;
    }

    .ctaContent {
      position: absolute;
      bottom: 15%;
      right: 0;
      width: 360px;
      z-index: 99;
      text-shadow: 0 0  3px $colorMainGreyBlue;
      
      > h1 {
        color: white;
        font-weight: bold;
        font-size: 60px;
        margin: 0;
      }

      > p {
        color: white;
        font-weight: bold;
        font-size: 24px;
        line-height: 40px;
        margin: 10px 0;
      }

      @include media("<=phone") {
        top: auto;
        bottom: 70px;
        left: 0;
        width: auto;
        padding-left: 20px;
        > h1 {
          font-size: 22px;
        }
        > p {
          font-size: 18px;
          margin: 8px 0;
        }
      }

    }
  }

}
