@import '../../global';

.ProductImageCarousel {
  width: 500px;
  @include media("<=phone") {
    width: 100%;
  }


  .images:global(.swiper-container) {
    --swiper-navigation-color: rgba(0,0,0, 0.2);
    padding-bottom: 20px;


    :global(.swiper-button-prev) {
      font-weight: bold;  
      left: 10px;
    }

    :global(.swiper-button-next) {
      font-weight: bold;  
      right: 10px;
    }

    :global(.swiper-wrapper) {
      >div {
        width: 500px;
        height: 500px;
        padding: 50px;
        box-sizing: border-box;
        @include media("<=phone") {
          padding: 10px;
          height: calc(100vw - 20px);
        }
        max-height: 100vh;
        >img {
          width: 100%;
          height: 100%;
        }
      }
    }

    :global(.swiper-pagination) {
      left: 50%;
      bottom: 7%;
      transform: translateX(-50%);
      width: auto;

      :global(.swiper-pagination-bullet) {
        background: rgba(0, 0, 0, 0.3);
        opacity: 1;
        margin: 6px;
      }

      :global(.swiper-pagination-bullet-active) {
        background: transparent;
        border: 3px solid rgba(0, 0, 0, 0.3);
        margin: 3px;
      }
    }
  }


  .thumbs:global(.swiper-container) {
    width: 100%;
    :global(.swiper-wrapper) {
      :global(.swiper-slide) {
        width: 25%;
        height: 100%;
        opacity: 1;

        img {
          cursor: pointer;
          border: 2px solid white;
          height: 92px;
        }
      }

      :global(.swiper-slide-thumb-active) {
        img {
          border: 2px solid $colorMainGreyBlue;
        }
        opacity: 1;
      }
    }
  }
}