@import '../../../global';

.VideoBackground {
  height: 100%;
  background: $colorMainDarkBlue;
  background-image: url('https://i.vimeocdn.com/video/994103930_1440x810.jpg');
  background-repeat: no-repeat;
  background-size: calc(max(100vw, 100vh / 9 * 16));
  background-position: center;
  @include media("<=phone") {
    background-size: calc(max(100vw, 90vh / 9 * 16));
  }
  
  iframe {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: calc(max(100vw, 100vh / 9 * 16));
    height: calc(max(100vh, 100vw / 16 * 9));
    pointer-events: none;

    @include media("<=phone") {
      width: calc(max(100vw, 90vh / 9 * 16));
      height: calc(max(90vh, 100vw / 16 * 9));
    }
  }
  video {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100vh;
    z-index: -100;
  }
}