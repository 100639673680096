@import '../../../global';

.ChatCarousel {
  width: 550px;
  height: 711px;
  margin: 0 auto;
  padding: 56px;
  box-sizing: border-box;
  background: url('./images/tablet-clipart-xl.png');
  background-size: cover;
  position: relative;
  @include media("<=phone") {
    width: 100%;
    height: 100vh;
    padding: 0;
    background: none;
  }
  > div {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
  }
}