@import "../global";

.DefaultPageContent {
  padding-top: 105px;

  .page-container {
    margin: 50px auto;
    width: $pageWidthFull;
  }

  @include media("<=phone") {
    padding-top: 70px;
    .page-container {
      margin: 30px auto;
      width: 100%;
      padding: 0 10px;
      box-sizing: border-box;
    }
  }
}