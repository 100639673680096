@import '../../../global';

.EducationExplainSection {
  position: relative;
  padding-bottom: 50px;
  color: white;

  .sectionContainer {
    width: $pageWidthFull;
    margin: 0 auto;
    box-sizing: border-box;

  }

  @include media("<=phone") {
    padding-bottom: 20px;
    .sectionContainer {
      width: 100%;

      .headline {
        padding: 0 20px;
      }

      h1 {
        margin: 10px 0 0;
        font-size: 26px;
      }

      :global(.swiper-container) {
        --swiper-navigation-color: white;

        :global(.swiper-button-prev) {
          left: 5px;
        }

        :global(.swiper-button-next) {
          right: 5px;
        }
      }
    }
  }

  &::after {
    content: "";
    background-color: $colorMainGreyBlue;
    background-image: url(../../../assets/backgrounds/yellow_grid.png);
    opacity: 0.9;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }
}