@import '../../../global';

.SectionHeadline {
  text-align: center;
  
  &.darkBackground {
    color: white;
    .inner {
      border-color: white;
    }
  }
  
  .inner {
    display: inline-block;
    padding: 0 45px;
    border-bottom: 5px solid black;

    h1 {
      font-size: 40px;
      font-weight: bold;
      margin: 30px 0 10px;
    }

    h2 {
      font-size: 20px;
      font-weight: lighter;
      margin: 10px 0;
    }
    
    @include media("<=phone") {
      padding: 0;
      h1 {
        font-size: 28px;
      }
    }
  }
}