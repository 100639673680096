@import "global";

#rmOrganism {
  .hidden {
    display: none !important
  }
  
  .rmBase__comp--checkbox {
    margin: 10px 0;
    label {
      font-size: 13px;
      > input[type=checkbox] {
        margin: 0 6px;
      }
      > div {
        display: inline;
      }
    }
  }

  .rmBase__compError {
    color: $colorMainRed;
    font-weight: bold;
  }
  label {
    font-size: 14px;
    font-weight: bold;
  }

  input[type=text] {
    margin-top: 4px;
    width: 100%;
    height: 30px;
    border: 1px solid black;
    font-family: $font-stack;
    font-size: 15px;
    padding-left: 15px;
    appearance:none;
    cursor: pointer;
    box-sizing: border-box;

    &:focus {
      border-width: 2px;
      outline: 0;
    }
    &:hover {
      background: #eff8ef;
    }
  }

  button {
    margin-top: 10px;
    width: 100%;
    border: none;
    appearance:none;
    font-family: $font-stack;
    font-size: 15px;
    background: #44D179;
    cursor: pointer;
    padding: 5px 10px;
    min-height: 45px;
    
    &:hover {
      color: white;
      font-weight: bold;
    }

    &:focus {
      outline: 0;
    }
  }
}