@import "../global";

.TopMenu {
  position: absolute;
  margin-top: 30px;
  width: 100%;
  z-index: 100;

  .menu-container {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    padding: 15px 0 7px;
    width: $pageWidthFull;

    .logo {
      display: block;
      flex-grow: 1;
      text-align: left;
      padding: 10px 0;

      img {
        height: 40px;
      }
    }

    nav {
      height: 100%;

      ul {
        margin: 0;

        > li {
          display: block;
          float: left;
          padding: 20px 12px;

          &.separator {
            color: $colorMainDarkBlue;
            font-size: 20px;
            font-weight: bold;
          }

          > a {
            text-decoration: none;
            font-weight: bold;
            font-size: 18px;
            color: $colorMainDarkBlue;

            &:hover {
              color: $colorMainRed;
            }
          }

          &.active > a {
            color: $colorMainRed;
          }
        }
      }
    }
  }

  &.white-items {
    margin-top: 50px;

    .menu-container {
      background: rgba(155, 155, 170, 0.2);
      padding: 5px 50px;
      border-radius: 15px;

      .logo {
        padding-top: 17px;
      }
    }

    .menu-container nav ul {
      /*text-shadow: 0px 0px 2px $colorMainGreyBlue;*/
      li {
        > a {
          color: white;
          font-size: 20px;

          &:hover {
            color: $colorMainRed;
          }
        }
        
        &.separator {
          color: white;
        }

        &.active > a {
          color: $colorMainRed;
        }

      }
    }
  }

  @include media("<=phone") {
    margin-top: 0;
    .menu-container, &.white-items .menu-container {
      padding: 0;
      margin: 0;
      border-radius: 0;
      border: none;
      background: none;
      width: 100%;

      .logo {
        margin-left: 25px;
        margin-top: 37px;
        padding: 0;
        img {
          height: 25px;
        }
      } 

      nav {
        display: none;
      }
    }

    .bm-burger-button {
      position: fixed;
      width: 26px;
      height: 26px;
      right: 20px;
      border-radius: 5px;
      top: calc(var(--menu-button-top) + 25px);
      border: 1px solid rgba(0, 0, 0, 0.1);
      background: white;
      padding: 10px;
      transition: top 0.5s;
    }

    &.white-items {
      margin-top: 0;

      .menu-container {
      }

      .bm-burger-button {
        background: $colorMainDarkBlue;
        color: white;
        border: 1px solid rgba(255, 255, 255, 0.3);
      }
    }

    /*
    Sidebar wrapper styles
    Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
    */
    .bm-menu-wrap {
      position: fixed;
      height: 100%;

      .bm-cross-button {
        height: 40px !important;
        width: 40px !important;
        color: #333;
        top: 20px !important;
      }
    }

    /* General sidebar styles */
    .bm-menu {
      background-color: white;
      background-image: url(../assets/backgrounds/squared_metal/squared_metal_@2X.png);
      background-size: 134px;
      padding: 24px 1.5em 0;
      font-size: 1.15em;

      .bm-item-list {
        position: relative;

        .bm-item {
          outline: none;
        }

        .bm-item.top-logo {
          position: relative;

          img {
            width: 80%;
          }
        }

        ul.bm-item {
          margin: 10px 20px 30px;
          padding-inline-start: 0;

          > li {
            border-bottom: 1px solid #666;
            list-style: none;
            padding: 20px 20px;

            > a {
              color: $colorMainDarkBlue;
              text-decoration: none;
              font-size: 18px;
              font-weight: bold;
            }

            &.active {
              > a {
                color: $colorMainRed;
              }
            }
          }
        }

        .bm-item.bottom-logo {
          margin: 0 auto;
          width: 150px;

          img {
            width: 150px;
          }
        }
      }
    }

    /* Morph shape necessary with bubble or elastic */
    .bm-morph-shape {
      fill: #373a47;
    }

    /* Styling of overlay */
    .bm-overlay {
      background: rgba(0, 0, 0, 0.3);
    }

  }
}