@import '../../../global';

.ChatCarouselPage {
  position: relative;
  height: 100%;
}

.bigTypingMessage {
  position: absolute;
  top: 50%;
  left: 80px;
  transform: translateY(-50%);
  display: flex;
  margin: 0 auto;
  width: 350px; 
  .logo {
    >img {
      width: 80px;
    }
  }
  .message {
    flex: 1;
    font-size: 32px;
    padding: 40px 20px;
  }
  @include media("<=phone") {
    width: 100%;
    left: 15%;
    .logo {
      >img {
        width: 60px;
      }
    }
    .message {
      font-size: 26px;
      padding: 30px 15px;
      color: white;
    }
  }
}

.typingMessage {
  margin-top: 30px;
  margin-left: auto;
  margin-right: 40px;
  display: flex;
  width: 150px; 
  .logo {
    >img {
      width: 40px;
    }
  }
  .message {
    flex: 1;
    font-size: 16px;
    padding: 20px 10px;
  }
  @include media("<=phone") {
    .message {
      color: white;
    }
  }
}