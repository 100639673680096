@import '../../../global';

.EduExplainCard {
  display: flex;
  flex-direction: row;
  margin: 0 50px;

  .image {
    position: relative;
    width: 250px;
    height: 250px;
    >img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 150px;
    }
  }
  .content {
    position: relative;
    background: rgba(255, 255, 255, 0.2);
    flex: 1;
    margin: 20px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      width: 0;
      height: 0;
      border: 12px solid transparent;
      border-right-color: rgba(255, 255, 255, 0.2);
      border-left: 0;
      margin-top: -12px;
      margin-left: -12px;
    }
  }

  &.textLeft {
    flex-direction: row-reverse;
    .content:after {
      left: auto;
      right: -12px;
      border: 12px solid transparent;
      border-left-color: rgba(255, 255, 255, 0.2);
      border-right: 0;
    }
  }

  @include media("<=phone") {
    &, &.textLeft {
      flex-direction: column-reverse;
      margin: 10px 10px;
      .image {
        width: 100%;
        box-sizing: border-box;
        height: 130px;
        margin: 0;
        >img {
          width: 120px;
        }
      }
      
      .content {
        min-height: 330px;
      }
      
      .content:after {
        content: '';
        position: absolute;
        top: auto;
        bottom: 0;
        left: 50%;
        width: 0;
        height: 0;
        border: 12px solid transparent;
        border-top-color: rgba(255, 255, 255, 0.2);
        border-bottom: 0;
        margin-left: -12px;
        margin-bottom: -12px;
      }
    }
  }
}