.StickyAtomFoto {
  padding: 10px;
  background-color: white;
  position: relative;
  left: -11px;
  margin: 60px auto 0;
  width: 259px;
  height: 300px;
  box-sizing: border-box;
  --sticky-note-rotation: 0deg;
  --sticky-tape-rotation: 0deg;
  --sticky-tape-scale: 1;
  
  transform: rotate(var(--sticky-note-rotation));
  
  .content {
    position: relative;
    width: 237px;
    height: 237px;
    border: 1px solid #aaa;
    box-sizing: border-box;
    margin: 0;
    >img, .hoverContent {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      opacity: 1;
      transition: opacity 0.2s;
    }
    .hoverContent {
      color: #333;
      padding: 0 10px;
      opacity: 0;
      p {
        font-size: 14px;
      }
    }
    &:hover {
      img {
        opacity: 0;
      }
      .hoverContent {
        opacity: 1;
      }
    }

  }
  img.tape {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) scaleX(var(--sticky-tape-scale)) rotate(var(--sticky-tape-rotation));
    width: 150px;
  }
}