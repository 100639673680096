@import '../../global';

.ProductNotReadyYet {
  position: relative;
  margin-top: 40px;
  padding: 10px 40px;

  > p {
    font-family: $font-stack-handwriting;
    font-size: 24px;
    font-weight: bold;
    line-height: 45px;
    position: relative;
  }
  @include media("<=phone") {
    > p {
      font-size: 20px;
    }
    padding: 10px 20px;
  }
  &:before {
    background-image: url(../../assets/backgrounds/notebook/notebook_@2X.png);
    background-size: 300px;
    border-radius: 15px;
    
    opacity: 0.6;
    
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
  }
}