@import "../../../../global";

.BooleanArray{
  margin: 20px auto;
  .boolIcon {
    display: inline-block;
    width: 28px;
    height: 28px;
    margin: 3px;
    border: 3px solid #333;
    background: white;
    border-radius: 50%;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    
    &.trueValue {
      background: $colorMainGreen;
      color: #333;
    }
    &.falseValue {
    }
  }
}