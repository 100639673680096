@import "../../../global";

.InteractiveAssembly {
  position: relative;
  width: 100%;
  height: 500px;
  margin-top: 40px;
  
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  user-select: none;
  
  
  .box {
    display: none;
    position: absolute;
    box-sizing: border-box;
    background: white;
    background-image: url(../../../assets/backgrounds/squared_metal/squared_metal_@2X.png);
    background-size: 134px;
    border: 3px solid $colorMainRed;
    border-radius: 7px;
    transition: opacity 1s;
    opacity: 0;
    padding: 10px;
    color: #333;
    box-shadow: white 0 0 10px;
    text-align: center;
    z-index: 3;

    h1 {
      font-size: 20px;
      margin: 0;
      text-align: left;
    }
    
    p {
      font-size: 14px;
      margin-top: 10px;
      text-align: left;
    }
    
    button {
      padding: 7px 100px;
      font-size: 14px;
      font-weight: bold;
      border: 3px solid #333;
      background: white;
      outline: none;
      color: #333;
      cursor: pointer;
      &:hover {
        background: $colorMainGreen;
        color: white;
      }
    }

    
    &.showBox {
      display: block;
      opacity: 0.9;
    }
    
    &.boxDisabled {
      opacity: 0.3;
      &.restartButton, &.backButton, &.forwardButton {
        &:hover {
          background: white;
          color: #333;
        }
      }
    }

    &.stepCongratulation {
      top: 150px;
      left: 250px;
      width: 480px;
      height: 190px;
    }

    &.stepKeyboard {
      right: 0px;
      top: 50px;
      width: 470px;
      height: 190px;
    }

    &.stepKeyboardData {
      left: 130px;
      top: 20px;
      width: 420px;
      height: 280px;
    }

    &.stepOperators {
      left: 430px;
      top: 230px;
      width: 470px;
      height: 280px;
    }

    &.stepOperatorColor {
      left: 450px;
      top: 120px;
      width: 440px;
      height: 250px;
    }

    &.stepLedRing {
      left: 350px;
      top: 140px;
      width: 440px;
      height: 200px;
    }

    &.stepLedRingAction {
      left: 350px;
      top: 50px;
      width: 520px;
      height: 190px;
    }

    &.stepLedRingSuccess {
      left: 350px;
      top: 50px;
      width: 520px;
      height: 250px;
    }

    &.stepValueInput {
      left: 20px;
      top: 50px;
      width: 350px;
      height: 340px;
    }

    &.stepValueInputData {
      left: 70px;
      top: 50px;
      width: 350px;
      height: 300px;
    }

    &.stepValueInputSuccess {
      left: 70px;
      top: 50px;
      width: 350px;
      height: 250px;
    }

    &.stepPower {
      right: 150px;
      top: 180px;
      width: 320px;
      height: 280px;
    }

    &.stepLetsplay {
      top: 120px;
      left: 250px;
      width: 480px;
      height: 190px;
    }

    &.restartButton, &.backButton, &.forwardButton {
      right: 0px;
      top: 0;
      cursor: pointer;
      &:hover {
        background-color: $colorMainGreyBlue;
        color: white;
      }
    }
    &.restartButton {
      padding: 3px 10px;
      font-weight: bold;
    }
    &.backButton, &.forwardButton {
      width: 30px;
      height: 30px;
      padding: 0;
    }
    &.backButton {
      right: 40px;
    }

  }
  
  .fadeOut {
    display: block;
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    background: #333;
    opacity: 0;
    pointer-events: none;
    border-radius: 20px;
    z-index: 2;
    transition: opacity 1.5s;
    &.showFadeout {
      display: block;
      opacity: 0.8;
    }
  }

  @include media("<=phone") {
    width: calc(150vw - 15px);
    height: 500px;
    transform-origin: top left;
    transform: scale(0.666) translateX(-130px);
    margin: 0 -15px;
    .boxCongratulation {
      top: 50%;
      width: 50%;
      height: auto;
      transform-origin: top left;
      transform: scale(1.8);
    }
    .fadeOut {
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      transform: translateX(130px) scale(1.5) translateY(100px);
    }
    .box {
      top: 500px!important;
      left: 130px!important;
      width: 100%!important;
      height: auto!important;
      &.backButton, &.forwardButton {
        display: none;
      }
      &.restartButton {
        width: 70vw!important;
        left: 50% !important;
      }
    }
  }

}