@import '../../../global';

.ManufacturingSection {
  position: relative;
  padding-bottom: 50px;

  .blendingBackground {
    .imagesContainer {
      background: rgba(155, 155, 170, 0.2);
      margin: 0 -20px;
      border-radius: 20px;
      .images {
        height: 415px;
        width: 700px;
        margin: 20px auto;
        padding: 30px 0;
        position: relative;

        :global(.swiper-container) {
          --swiper-navigation-color: rgba(0,0,0, 0.7);
          width: 100%;
          height: 100%;
    
          :global(.swiper-wrapper) {
            position: relative;
            img {
              padding: 0 100px 40px;
              width: calc(100% - 200px);
              height: calc(100% - 40px);
            }
          }
          :global(.swiper-pagination) {
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
            width: auto;
  
            :global(.swiper-pagination-bullet) {
              background: rgba(0,0,0, 0.7);
              opacity: 1;
              margin: 6px;
            }
            :global(.swiper-pagination-bullet-active) {
              background: transparent;
              border: 3px solid rgba(0,0,0, 0.7);
              margin: 3px;
            }
          }
        }

      }

      @include media("<=phone") {
        background: none;
        border-radius: 0;
        .images {
          width: 100%;
          height: auto;
          padding: 0;

          :global(.swiper-container) {
            --swiper-navigation-color: rgba(255, 255, 255, 0.7);
            width: 100%;
            height: 100%;

            :global(.swiper-wrapper) {
              img {
                padding: 0;
                width: 100%;
              }
            }
          }
        }

      }
    }
    
    .icons {
      display: flex;
      flex-direction: row;
      > div {
        width: 33%;
      }
    }
    
    @include media("<=phone") {
      .video {
        width: 100%;
        height: fit-content;
        iframe {
          position: relative;
          left: 0;
          transform: none;
          width: 100%;
          height: 60vw;
        }
      }
      .icons {
        display: block;
        > div {
          width: 100%;
          > div {
            display: flex;
            img {
              width: 100px;
              height: 100px;
            }
            h1 {
              flex: 1;
              padding: 0 10px;
              font-size: 18px;
              font-weight: bold;
            }
          }
        }
        :global(.swiper-container) {
          padding-bottom: 20px;
          :global(.swiper-pagination) {
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
            width: auto;
            
            :global(.swiper-pagination-bullet) {
              background: black;
              opacity: 1;
              margin: 6px;
            }
            :global(.swiper-pagination-bullet-active) {
              background: transparent;
              border: 3px solid black;
              margin: 3px;
            }
          }
        }
      }
    }
  }

  .blendingBackground:after {
    content: "";
    background-color: white;
    background-image: url(../../../assets/backgrounds/squared_metal/squared_metal_@2X.png);
    background-size: 134px;
    opacity: calc(var(--scroll-enter) * 0.9);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;

    @include media("<=phone") {
      opacity: 0.9;
    }
  }
}
