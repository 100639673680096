.Atom {
  position: absolute;
  --atom-scale: 0.25;
  --atom-top: 0;
  --atom-left: 0;
  --atom-rotation: 0deg;
  left: calc(var(--atom-left) + -1 * var(--atom-image-zero-x) * var(--atom-scale));
  top: calc(var(--atom-top) + -1 * var(--atom-image-zero-y) * var(--atom-scale));
  width: calc(var(--atom-image-width) * var(--atom-scale));
  height: calc(var(--atom-image-height) * var(--atom-scale));
  transform-origin: calc(var(--atom-image-zero-x) * var(--atom-scale)) calc(var(--atom-image-zero-y) * var(--atom-scale));
  transform: rotate(var(--atom-rotation));
  background-size: cover;
  z-index: 1;
  
  &.highlight {
    z-index: 3;
  }
  
  .AtomImage {
    position: absolute;
    background-size: cover;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.AtomFrame {
  position: absolute;
  --atom-scale: 0.25;
  --atom-top: 0;
  --atom-left: 0;
  --atom-rotation: 0deg;
  left: calc(var(--atom-left));
  top: calc(var(--atom-top));
  width: var(--atom-image-width);
  height: var(--atom-image-height);
  transform-origin: top left;
  transform: 
        scale(var(--atom-scale))
        rotate(var(--atom-rotation))
    translateX(calc(var(--atom-image-zero-x) * -1))
    translateY(calc(var(--atom-image-zero-y) * -1))
  ;
  background-size: cover;
  z-index: 1;
  
  &.highlight {
    z-index: 3;
  }
  
  .AtomImage {
    position: absolute;
    background-size: cover;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}