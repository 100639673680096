.EvaHeaderStickyNote {
  --sticky-note-rotation: 2deg;
  position: relative;
  width: 255px;
  height: 255px;
  margin: 0 auto;
  
  img.noteImage, img.hoverImage {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity 1.2s;
  }
  .noteImage {
    opacity: 1;
    transform: rotate(var(--sticky-note-rotation));
  }
  .hoverImage {
    opacity: 0;
  }
  &:hover {
    .noteImage {
      opacity: 0;
    }
    .hoverImage {
      opacity: 1;
    }
  }
}