@import '../../../global';

.NewsletterSection {
  position: relative;
  padding-top: 20px;
  padding-bottom: 50px;
  color: white;

  .headline {
    margin-bottom: 40px;
  }

  .sectionContainer {
    width: $pageWidthFull*0.9;
    box-sizing: border-box;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    position: relative;

    .register {
      box-sizing: border-box;
      flex: 1;
      padding: 0 30px;
      >h2 {
        text-align: center;
        font-size: 18px;
      }
      >iframe {
        border: 0;
      }
    }
    .space {
      width: 100px;
    }
    .social {
      flex: 1;
      margin-top: 70px;
      .singlebadge {
        margin-top: 20px;
        display: flex;
        .text {
          font-size: 22px;
          padding: 30px;
          a {
            color: white;
          }
        }
        .icon {
          img {
            width: 100px;
          }
        }
      }
      @include media("<=phone") {
        margin: 0;
        .singlebadge {
          width: 300px;
          margin: 20px auto 0;
        }
      }
    }


    @include media("<=phone") {
      width: 100%;
      display: block;
      .register {
        width: 100%;
      }
      .social {
        width: 100%;
      }
    }

  }

  &:after {
    content: "";
    background-color: $colorMainOrange;
    background-image: url(../../../assets/backgrounds/yellow_grid.png);
    opacity: 0.9;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }
  
}
