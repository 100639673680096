@import '../../../global';

.TaprolabsSection {
  position: relative;
  padding-top: 50px;
  padding-bottom: 50px;


  .sectionContainer {
    width: $pageWidthFull*0.9;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    position: relative;

    .logo {
      flex: 1;
      img {
        width: 350px;
      }
    }
    .text {
      flex: 1;
      padding: 0 20px;
      .headline {
        > div > div {
          padding: 0;
        }
      }
      p {
        margin: 30px 0 30px;
      }
      .button {
        text-align: center;
        > a {
          padding: 10px 20px;
        }
      }
    }

    @include media("<=phone") {
      width: 100%;
      display: block;
      .logo {
        width: 100%;
        height: 200px;
        text-align: center;
        img {
          width: 200px;
        }
      }
      .text {
      }
    }
  }

  &:after {
    content: "";
    background-color: white;
    background-image: url(../../../assets/backgrounds/squared_metal/squared_metal_@2X.png);
    background-size: 134px;
    opacity: calc(var(--scroll-enter) * 0.9);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;

    @include media("<=phone") {
      opacity: 0.9;
    }
  }
}