.SimpleValueInputAtom {
  --atom-image-width: 503px;
  --atom-image-height: 505px;
  --atom-image-zero-x: 248px;
  --atom-image-zero-y: 253px;
  --atom-dial-value: 0;
  --atom-dial-user-input: 0;
  
  background-image: url("./images/simple-value-input_plain.png");
  touch-action: none;
  
  .valueDial {
    position: absolute;
    width: 196px;
    height: 186px;
    margin-left: -98px;
    margin-top: -93px;
    left: var(--atom-image-zero-x);
    top: var(--atom-image-zero-y);
    background-image: url("./images/simple-value-input_cap.png");
    transform-origin: center;
    transform: rotate(calc(var(--atom-dial-value) * 300deg - 60deg));
  }
  &.userInputActive {
    z-index: 99;
    .valueDial {
      transform: scale(2) rotate(calc(var(--atom-dial-value) * 300deg - 60deg));
    }
  }
}