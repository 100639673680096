@import '../../../global';

.ExampleVideoCard {

  display: flex;
  flex-direction: row;
  @include media("<=phone") {
    display: block;
  }

  h2 {
    display: none;
    text-align: center;
    font-size: 20px;

    @include media("<=phone") {
      display: block;
    }
  }

  .image {
    position: relative;
    margin: 50px;
    height: 255px;
    width: 255px;
    >img {
      transition: opacity 0.5s;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      &.imageDefault {
        opacity: 1;
      }
      &.imageAnimated {
        opacity: 0;
      }
      &:hover {
        &.imageDefault {
          opacity: 0;
        }
        &.imageAnimated {
          opacity: 1;
        }
      }
    }
    @include media("<=phone") {
      display: none;
    }
  }
  
  .video {
    margin: 50px;
    flex: 1;
    height: 265px;
    box-shadow: 1px 1px 4px 2px #333333;
    background: #333333;
    @include media("<=phone") {
      margin: 10px 0;
      width: 100%;
      height: calc(100vw * 0.5);
      padding: 0;
      box-sizing: border-box;
    }
  }
}